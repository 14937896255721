/* eslint-disable no-console */

import { setAuth } from '../auth/auth';
import { useAuthenticateUserMutation } from '../graphql/generated-types';
import { useAuth } from './useAuth';
import { useSubscribeUser } from './useSubscribeUser';

export const useLogin = () => {
  const auth = useAuth();
  const [authenticateUser, { loading, error }] = useAuthenticateUserMutation();
  const {
    subscribeUser,
    loading: sLoading,
    error: sError,
  } = useSubscribeUser();

  const login = async (
    username: string,
    password: string,
    isRegistering: boolean,
    remember = false
  ) => {
    const { data } = await authenticateUser({
      variables: {
        username,
        password,
        // remember,
      },
    });
    // set token in local storage
    // redirect to dashboard
    if (
      data?.authenticateUser &&
      data?.authenticateUser?.user?.id &&
      data?.authenticateUser?.user?.username &&
      data?.authenticateUser?.jwt
    ) {
      const jwt = data?.authenticateUser?.jwt;
      // const refresh = data?.authenticateUser?.refresh as string;
      const userId = data.authenticateUser.user.id;
      // set auth in local storage
      const user: any = { ...data?.authenticateUser.user };
      user.otp = false;
      if (data?.authenticateUser.userProfile) {
        user.userProfile = { ...data?.authenticateUser.userProfile };
      }
      setAuth(user, jwt, data.authenticateUser.refresh as string, remember);
      // if (data.authenticateUser.userProfile && isBoolean(data.authenticateUser.userProfile.emailVerified)) {
      //   jwtControl.setEmailVerified(data.authenticateUser.userProfile.emailVerified);
      // }
      auth.login(user, jwt);
      if (isRegistering) {
        await subscribeUser(userId, data.authenticateUser.subscriptions as any);
      }

      return jwt;
    }
    return false;
  };

  return {
    login,
    loading: loading || sLoading,
    error: error || sError,
  };
};
