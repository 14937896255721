import { usePublicProgram } from '@drugfreesleep/app/(program)/course/program.gql';
import { Alert, Button } from 'flowbite-react';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { IoArrowForward } from 'react-icons/io5';

import { setAuth } from '../auth/auth';
import { useSetFirstNameMutation } from '../graphql/generated-types';
import { useAuth } from './useAuth';

export const SetDisplayName = () => {
  const auth = useAuth();
  const router = useRouter();
  const [setFirstName, { loading: firstNameLoading, error: firstNameError }] =
    useSetFirstNameMutation();
  const { program } = usePublicProgram();

  const [showFirstNameError, setFirstNameError] = useState(false);
  const [displayName, setDisplayName] = useState('');

  useEffect(() => {
    if (firstNameError) {
      setFirstNameError(true);
    }
  }, [firstNameError]);

  const handleSetFirstName = async () => {
    const { data } = await setFirstName({
      variables: {
        firstName: displayName,
      },
    });
    const user: any = { ...auth.user };
    if (data?.setFirstName?.userProfile) {
      user.userProfile = data?.setFirstName?.userProfile;
    }
    setAuth(user, data?.setFirstName?.jwt as string, auth.refresh as string);
    router.push(`/p/${program?.slug}/today`);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (displayName) {
      handleSetFirstName();
    }
  };

  return (
    <div>
      <form className="flex flex-col md:mx-16" onSubmit={handleSubmit}>
        <div>
          <div className="mb-3 text-center text-3xl font-semibold text-black">
            What should we call you?
          </div>
          <div className="flex flex-col justify-center">
            <div className="flex justify-center">
              <input
                className="form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-xl font-normal text-gray-700"
                style={{ maxWidth: '600px', minWidth: '350px' }}
                placeholder="First Name"
                value={displayName}
                onChange={(e) => {
                  setDisplayName(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-4 flex w-full justify-center">
          <Button
            style={{
              width: '170px',
              borderRadius: '2rem',
              backgroundColor: 'rgb(30 64 175)',
            }}
            className="bg-blue-800"
            type="submit"
            disabled={!displayName || firstNameLoading}
          >
            Next
            <IoArrowForward />
          </Button>
        </div>
        <div className="mt-2 text-center align-baseline text-black">
          <div
            className="cursor-pointer underline"
            onClick={() =>
              router.push(program ? `/p/${program?.slug}/today` : `/today`)
            }
          >
            Skip for now
          </div>
        </div>
        <div className="flex justify-center">
          {showFirstNameError && firstNameError && (
            <Alert className="mt-4" color="warning">
              {firstNameError.message}
            </Alert>
          )}
        </div>
      </form>
    </div>
  );
};
