import { usePublicProgram } from '@drugfreesleep/app/(program)/course/program.gql';
import useSubscriptionIds from '@drugfreesleep/utils/useSubscriptionIds';

import { useLogger } from '../analytics/useImpression';
import type { Subscription } from '../graphql/generated-types';
import {
  SubscriptionStateEnum,
  useCreateSubscriptionMutation,
  useResubscribeMutation,
} from '../graphql/generated-types';

export const useSubscribeUser = () => {
  const log = useLogger();
  const [subscribe, { loading: sLoading, error: sError }] =
    useCreateSubscriptionMutation();
  const [resubscribe, { loading: rLoading, error: rError }] =
    useResubscribeMutation();

  const { program } = usePublicProgram();
  const { orgId, therapistId } = useSubscriptionIds();

  const subscribeUser = async (
    userId: number,
    subscriptions?: Partial<Subscription>[] | null
  ) => {
    if (!program) {
      // No program to subscribe to
      return null;
    }
    const existingSub = subscriptions?.find(
      (sub) => sub.programId === program.id
    );
    if (existingSub) {
      // Already subscribed
      if (
        existingSub.orgId !== orgId ||
        existingSub.payload?.therapistId !== therapistId
      ) {
        // Resubscribe with correct orgId and therapistId
        try {
          const subData = await resubscribe({
            variables: {
              userId: userId as number,
              programId: program.id as number,
              orgId: orgId as number,
              therapistId: therapistId as number,
            },
          });
          return subData.data?.resubscribe?.subscription;
        } catch (e) {
          await log('subscription error', {
            subtype: 'error',
            payload: {
              message: 'Error resubscribing',
              error: e,
            },
          });
        }
      }

      return null;
    }
    try {
      const subData = await subscribe({
        variables: {
          input: {
            subscription: {
              type: 'open',
              state: SubscriptionStateEnum.Active,
              userId,
              programId: program?.id as number,
              orgId: orgId as number,
              meta: {
                url: window.location.href,
              },
              payload: {
                therapistId,
              },
            },
          },
        },
      });
      return subData.data?.createSubscription?.subscription;
    } catch (e) {
      await log('subscription error', {
        subtype: 'error',
        payload: {
          message: 'Error creating subscription',
          error: e,
        },
      });
      return null;
    }
  };
  let error = sError || rError;
  if (sError && sError.message.indexOf('unique_up')) {
    error = undefined;
  }
  return { subscribeUser, loading: sLoading || rLoading, error };
};
