import { AppConfig } from '@drugfreesleep/config/AppConfig';
import { useCallback, useEffect, useState } from 'react';

import { useAuth } from '../authentication/useAuth';
import type { CreateEventMutation } from '../graphql/generated-types';
import { useCreateEventMutation } from '../graphql/generated-types';

interface IImpressionProps {
  name?: string;
  orgId?: number;
  programId?: number;
  interactionId?: number;
  subtype?: string;
  payload?: any;
  meta?: any;
}

export const useImpression = (
  name: string,
  props: IImpressionProps,
  waiting: boolean[]
) => {
  const type = 'i';
  const { programId, interactionId, subtype, payload, meta } = props;
  const { user } = useAuth();
  const [alreadyRun, setAlreadyRun] = useState(false);
  const [createEvent, { data, loading, error }] = useCreateEventMutation();

  let shouldWait = false;
  waiting.forEach((w) => {
    shouldWait = shouldWait || w;
  });

  useEffect(() => {
    if (!shouldWait && !alreadyRun) {
      setAlreadyRun(true);
      createEvent({
        variables: {
          input: {
            event: {
              userId: user?.id || 0,
              programId,
              interactionId,
              type,
              subtype,
              name,
              payload,
              meta,
            },
          },
        },
      });
    }
  }, [shouldWait]);

  return { data, loading, error };
};

type CreateEvent = [
  (event: IImpressionProps) => Promise<any>,
  { data: CreateEventMutation | null | undefined; loading: boolean; error: any }
];

export const useCreateProgramEvent = (defaultName: string): CreateEvent => {
  const type = 'p';
  const { user } = useAuth();
  const [createEvent, { data, loading, error }] = useCreateEventMutation();

  return [
    async (props: IImpressionProps) => {
      const { programId, interactionId, subtype, payload, meta, name } = props;
      return createEvent({
        variables: {
          input: {
            event: {
              userId: user?.id || 0,
              programId,
              interactionId,
              type,
              subtype,
              name: name || defaultName,
              payload,
              meta,
            },
          },
        },
      });
    },
    { data, loading, error },
  ];
};

export const useLog = (
  name: string,
  props: IImpressionProps,
  waiting?: boolean[]
) => {
  const type = 'l';
  const { programId, interactionId, subtype, payload, meta } = props;
  const { user } = useAuth();
  const [alreadyRun, setAlreadyRun] = useState(false);
  // const [createEvent, { data, loading, error }] = useCreateEventMutation();

  let shouldWait = false;
  waiting?.forEach((w) => {
    shouldWait = shouldWait || w;
  });

  useEffect(() => {
    if (!shouldWait && !alreadyRun && typeof window !== 'undefined') {
      setAlreadyRun(true);
      fetch(`${AppConfig.api_url}/event`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'omit',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          event: {
            userId: user?.id || 0,
            programId,
            interactionId,
            type,
            subtype,
            name,
            payload,
            meta,
          },
        }),
      });
    }
  }, [shouldWait]);

  return alreadyRun;
};

export const useLogger = () => {
  const type = 'l';
  const { user } = useAuth();

  const log = useCallback((name: string, props: IImpressionProps) => {
    const { programId, interactionId, subtype, payload, meta } = props;
    const event = {
      userId: user?.id || 0,
      programId,
      interactionId,
      type,
      subtype,
      name,
      payload,
      meta,
    };
    // eslint-disable-next-line no-console
    console.log('logging', { event });
    fetch(`${AppConfig.api_url}/event`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        event,
      }),
    });
  }, []);

  return log;
};
