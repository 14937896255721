import { useRouter } from 'next/router';

const useSubscriptionIds = () => {
  const router = useRouter();
  const { subscription } = router.query;

  return {
    orgId: subscription
      ? parseInt((subscription as string[])[0], 10)
      : subscription,
    therapistId: subscription
      ? parseInt((subscription as string[])[1], 10)
      : subscription,
  };
};

export default useSubscriptionIds;
