import router from 'next/router';
import React from 'react';

interface IProps {
  message: string;
  onClick: () => void;
  slug?: string | null;
}
export const OTPErrorHandler = ({ message, onClick, slug }: IProps) => {
  if (message === 'No consent found') {
    return (
      <div>
        <div>
          New accounts require an invitation.{' '}
          <span
            className="underline"
            onClick={() =>
              router.push(
                slug ? `/p/${slug}/contact-support` : `/contact-support`
              )
            }
          >
            Click Here
          </span>{' '}
          to request one.
        </div>
        <div className="mt-3">
          If you signed up using a password{' '}
          <span className="underline" onClick={onClick}>
            click here
          </span>
          .
        </div>
      </div>
    );
  }
  return <div>{message}</div>;
};
