import type { MutationFunctionOptions } from '@apollo/client';
import { usePublicProgram } from '@drugfreesleep/app/(program)/course/program.gql';
import { useCallback } from 'react';

import { setAuth } from '../auth/auth';
import type {
  Exact,
  UserRegistrationMutation,
} from '../graphql/generated-types';
import { useUserRegistrationMutation } from '../graphql/generated-types';
import { useAuth } from './useAuth';
import { useSubscribeUser } from './useSubscribeUser';

export interface RegistrationFormValues {
  username: string;
  password: string;
  remember: boolean;
}

// return type for the hook that matches useUserRegistrationMutation
type RegistrationMutation = ReturnType<typeof useUserRegistrationMutation>;

export const useRegistration = (): RegistrationMutation => {
  const auth = useAuth();
  const [register, { loading, error, ...rest }] = useUserRegistrationMutation();
  const {
    subscribeUser,
    loading: sLoading,
    error: sError,
  } = useSubscribeUser();

  const { program } = usePublicProgram();

  const onSubmit = useCallback(
    async (
      options?: MutationFunctionOptions<
        UserRegistrationMutation,
        Exact<{
          username: string;
          password: string;
        }>
      >
    ) => {
      const { data, errors } = await register(options);
      const allErrors = [...(errors || [])];
      if (
        data?.registerUser &&
        data?.registerUser?.user?.id &&
        data?.registerUser?.user?.username &&
        data?.registerUser?.jwt
      ) {
        const userId = data.registerUser.user.id;
        // set user
        const user: any = { ...data?.registerUser.user };
        user.otp = false;
        setAuth(
          user,
          data?.registerUser?.jwt,
          data.registerUser.refresh as string
        );
        auth.login(user, data?.registerUser.jwt);
        if (program && program.id) {
          await subscribeUser(userId, [] as any);
        }
      }
      return {
        data,
        loading,
        errors: allErrors.length === 0 ? undefined : allErrors,
      };
    },
    [program]
  );

  return [
    onSubmit,
    { loading: loading || sLoading, error: error || sError, ...rest },
  ];
};
