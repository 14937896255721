/* eslint-disable unused-imports/no-unused-vars */
import { gql } from '@apollo/client';
import { useConfig } from '@drugfreesleep/config/useConfig';
import { getApolloClient } from '@drugfreesleep/modules/graphql/apollo';
import {
  useProgramWithPayloadQuery,
  usePublicNoteQuery,
  usePublicProgramQuery,
} from '@drugfreesleep/modules/graphql/generated-types';
import useProgramSlug from '@drugfreesleep/utils/useProgramSlug';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

const splitSlug = (input: string) => {
  if (!input) {
    return {
      organizationSlug: '',
      programSlug: '',
    };
  }
  const [organizationSlug, programSlug] = input.includes('__')
    ? input.split('__')
    : ['weller-labs', input];
  return { organizationSlug, programSlug };
};

export const useProgram = (
  sections: string[],
  poll?: boolean,
  programId?: number
) => {
  const config = useConfig();
  let programSlug = useProgramSlug() as string;
  // const programConfig = useProgramConfig(programSlug);
  if (programId) {
    programSlug = Object.values(config.programs).find(
      (program) => program.id === programId
    )?.slug as string;
  }

  let queryOptions;
  if (config?.programs[programSlug]) {
    queryOptions = {
      variables: {
        organizationSlug: config?.programs[programSlug]?.organizationSlug,
        programSlug: config?.programs[programSlug]?.slug,
        sections,
        versionId: config?.programs[programSlug]?.versionId,
      },
      skip: !config || !programSlug, // slug is undefined while router.isReady is true,
      pollInterval: poll ? 500 : undefined,
    };
  } else {
    const { organizationSlug, programSlug: programSlug2 } =
      splitSlug(programSlug);
    queryOptions = {
      variables: {
        organizationSlug,
        programSlug: programSlug2,
        sections,
        versionId: -1,
      },
      skip: !config || !programSlug, // slug is undefined while router.isReady is true,
      pollInterval: poll ? 500 : undefined,
    };
  }

  const { data, loading, error } = useProgramWithPayloadQuery(queryOptions);
  return {
    program: data?.programWithPayload?.program,
    interactions: data?.programWithPayload?.interactions,
    loading,
    error,
  };
};

export const useProgramConfig = (slug?: string) => {
  const client = getApolloClient();
  const config = useConfig();
  const { organizationSlug, programSlug } = splitSlug(slug || '');

  const queryOptions = {
    client,
    variables: {
      organizationSlug,
      programSlug,
      sections: ['config'],
      versionId: -1,
    },
    skip: !slug,
  };
  const { data, loading, error } = useProgramWithPayloadQuery(queryOptions);
  let program = null;
  const programConfig = useMemo(() => {
    let pc = null;
    if (data?.programWithPayload) {
      program = data.programWithPayload.program;
      /*
     * Example program.payload.config
     *
      "display": {
        "logo": "audio",
        "name": "The 5 Sense Method" // overrides program.name for display
      },
      "components": {
        "about": {
          "id": 29
        },
        "homepage": {
          "id": 62
        }
      },
    */
      pc = {
        ...program.payload.config,
        id: program.id,
        organizationSlug,
        display: {
          ...(program.payload.config?.display
            ? program.payload.config.display
            : {}), // Create a new object for display
          logo: program.payload?.config?.display?.logo || 'audio', // Ensure logo is set
          name: program.payload?.config?.display?.name || program.name, // Ensure name is set
        },
        components: {
          ...config.components,
          ...(program.payload.config?.components || {}),
        },
      };
    }
    return pc;
  }, [data?.programWithPayload?.program.id]);
  return {
    program,
    programConfig,
    loading,
    error,
  };
};

// hook to query public program using programSlug and usePublicProgramQuery
// splitting slug into organizationSlug and programSlug
export const usePublicProgram = () => {
  const client = getApolloClient();
  const slug = useProgramSlug();
  const { organizationSlug, programSlug } = splitSlug(slug);
  const queryOptions = {
    client,
    variables: {
      slug: programSlug,
      organizationSlug,
    },
    skip: !slug,
  };
  const { data, loading, error } = usePublicProgramQuery(queryOptions);
  return {
    program: data?.publicProgram,
    loading,
    error,
  };
};

export const getConfigComponentId = (config: any, component: string) => {
  return parseInt(config?.components?.[component]?.id, 10);
};

export const getProgramComponentId = (program: any, component: string) => {
  return getConfigComponentId(program?.config, component);
};

export const usePublicProgramComponent = (component: string) => {
  const config = useConfig();
  const client = getApolloClient();
  const { program, loading, error } = usePublicProgram();
  const id = getProgramComponentId(program, component);
  const fallbackId = getConfigComponentId(config, component);
  const {
    data,
    loading: noteLoading,
    error: noteError,
  } = usePublicNoteQuery({
    client,
    variables: {
      id: id || fallbackId,
    },
    skip: !!program && !(id || fallbackId),
  });
  const note = data?.note;
  return { note, loading: loading || noteLoading, error: error || noteError };
};

const useProgramSlug2 = () => {
  const router = useRouter();
  // warning: router.query is initially an empty object, until router.isReady is true
  return {
    isReady: router.isReady,
    slug: router.query.path as string,
    ssr: typeof window === 'undefined',
  };
};

// hook to get program display name and logo using usePublicProgram()
export const useProgramDisplay = () => {
  const config = useConfig();
  const { slug, isReady, ssr } = useProgramSlug2();
  const { program, loading } = usePublicProgram();

  if (!program && !loading) {
    return {
      name: config?.display?.name,
      logo: config?.display?.logo || 'audio',
    };
  }
  return {
    name: program?.config?.display?.name || program?.name,
    logo: program?.config?.display?.logo || 'audio',
  };
};

// export const ProgramAtVersion = gql`
//   query ProgramAtVersion($id: Int!, $versionId: Int!) {
//     program(id: $id) {
//       __typename
//       id
//       userId
//       orgId
//       type
//       name
//       slug
//       payload
//       meta
//       isPublic
//       createdAt
//       updatedAt
//       atVersion(input: { versionId: $versionId }) {
//         id
//         userId
//         orgId
//         type
//         name
//         slug
//         payload
//         meta
//         isPublic
//         createdAt
//         updatedAt
//       }
//     }
//   }
// `;
export const Program = gql`
  query Program($id: Int!) {
    program(id: $id) {
      __typename
      id
      userId
      orgId
      type
      name
      slug
      payload
      meta
      isPublic
      createdAt
      updatedAt
    }
  }
`;

export const ProgramWithPayload = gql`
  query ProgramWithPayload(
    $organizationSlug: String!
    $programSlug: String!
    $sections: [String!]!
    $versionId: Int
  ) {
    programWithPayload(
      input: {
        programSlug: $programSlug
        organizationSlug: $organizationSlug
        sections: $sections
        versionId: $versionId
      }
    ) {
      program {
        id
        name
        payload
        orgId
      }
      interactions {
        id
        name
        payload
      }
    }
  }
`;

export const PublicProgram = gql`
  query PublicProgram($organizationSlug: String!, $slug: String!) {
    publicProgram(input: { organizationSlug: $organizationSlug, slug: $slug }) {
      id
      name
      slug
      config
    }
  }
`;
