import 'react-phone-number-input/style.css';

import { usePublicProgram } from '@drugfreesleep/app/(program)/course/program.gql';
import useWindowSize from '@drugfreesleep/utils/useWindowSize';
import classNames from 'classnames';
import { Alert, Button, Label } from 'flowbite-react';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { IoArrowBack, IoArrowForward } from 'react-icons/io5';
import PhoneInput from 'react-phone-number-input/input';

import { useRequestOtpMutation } from '../graphql/generated-types';
import { OTPErrorHandler } from './OTPErrorHandler';
import { SetDisplayName } from './SetDisplayName';
import { useAuth } from './useAuth';
import { useAuthenticateOTP } from './useAuthenticateOTP';

interface IProps {
  setShowMobileOTP: (a: boolean) => void;
  showRegister: boolean;
  isModal?: boolean;
  routeHome?: boolean;
  slug?: string | null;
}
export const MobileOTP = ({
  setShowMobileOTP,
  showRegister,
  isModal = false,
  routeHome,
}: IProps) => {
  const router = useRouter();
  const auth = useAuth();

  const [requestOTP, { loading: requestOTPLoading, error: requestOTPError }] =
    useRequestOtpMutation();

  const {
    authenticateOTP,
    loading: authenticateOTPLoading,
    error: authenticateOTPError,
  } = useAuthenticateOTP();

  const [mobileNumber, setMobileNumber] = useState('');

  const [tokenKey, setTokenKey] = useState('');
  const [id, setId] = useState<null | string>(null);
  const { isMobile } = useWindowSize();
  const { program, loading } = usePublicProgram();

  const [showSendRegisterError, setShowSendRegisterError] = useState(false);
  const [showVerifyLoginError, setShowVerifyLoginError] = useState(false);

  const [showDisplayName, setShowDisplayName] = useState(false);

  useEffect(() => {
    if (requestOTPError) {
      setShowSendRegisterError(true);
    }
    if (authenticateOTPError) {
      setShowVerifyLoginError(true);
    }
  }, [requestOTPError, authenticateOTPError]);

  const handleRequestMobileOTP = async (newMobileNumber: string) => {
    const data = await requestOTP({
      variables: {
        mobile: newMobileNumber,
        domain: window.location.origin,
        consent: showRegister,
      },
    });
    setId(data.data?.requestOTP?.id as string);
  };

  const handleMobileSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (mobileNumber) {
      handleRequestMobileOTP(mobileNumber);
    }
  };

  const handleMobileChange = (newMobileNumber: unknown) => {
    setMobileNumber(newMobileNumber as string);
    // Hide alert messages when user starts typing
    setShowSendRegisterError(false);
  };

  const handleCodeChange = (e: any) => {
    setTokenKey(e.target.value);
    // Hide alert messages when user starts typing
    setShowVerifyLoginError(false);
  };

  const goToToday = () => {
    router.push(program ? `/p/${program.slug}/today` : `/today`);
  };
  const handleVerify = async (innerId: string, innerTokenKey: string) => {
    const user = await authenticateOTP(
      innerId as string,
      innerTokenKey,
      showRegister
    );
    if (user) {
      if (showRegister) {
        setShowDisplayName(true);
      } else {
        router.push(program ? `/p/${program.slug}/today` : `/today`);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (id && id.length > 0 && tokenKey && tokenKey.length === 4) {
      await handleVerify(id, tokenKey);
    }
  };

  useEffect(() => {
    if (
      auth.authenticated &&
      routeHome &&
      !showDisplayName &&
      !tokenKey &&
      !loading
    ) {
      goToToday();
    }
  }, [auth, loading]);

  if (showDisplayName) {
    return <SetDisplayName />;
  }

  return (
    <div className={classNames('flex justify-center')}>
      {id ? (
        <div className={`${isModal && 'md:mt-10'}`}>
          <div className="absolute top-10 mb-10">
            <IoArrowBack
              onClick={() => {
                setId(null);
              }}
              className="text-2xl"
            />
          </div>
          <form
            className={classNames('flex flex-col md:mx-16', isModal && 'mt-10')}
            onSubmit={handleSubmit}
          >
            <div className="mb-3 text-center text-xl font-semibold text-black md:text-2xl">
              Check your mobile phone!
            </div>
            {!isModal && (
              <div className="mb-3 flex justify-center">
                <div className="text-center text-xs text-black md:text-sm">
                  We sent a 4-digit confirmation code to {mobileNumber}. It
                  expires shortly, so enter it soon.
                </div>
              </div>
            )}
            <div className="flex flex-col justify-center">
              <div className="mb-2 block">
                <Label
                  style={{ color: 'black' }}
                  htmlFor="otp"
                  value="Your code"
                />
              </div>
              <input
                id="otp"
                name="otp"
                type="tel"
                inputMode="numeric"
                autoComplete="one-time-code"
                maxLength={4}
                required
                onChange={handleCodeChange}
                autoFocus
              />
              <div className="flex w-full justify-center">
                <div className="absolute bottom-12 flex flex-col justify-center ">
                  <div className="mb-5 flex w-full justify-center">
                    <Button
                      style={{
                        width: '170px',
                        borderRadius: '2rem',
                        backgroundColor: 'rgb(30 64 175)',
                      }}
                      className="bg-blue-800"
                      type="submit"
                      disabled={authenticateOTPLoading || tokenKey.length !== 4}
                    >
                      <div className="mr-1">Verify</div>
                      <IoArrowForward />
                    </Button>
                  </div>
                  <div className="flex text-black">
                    <div className="mr-1">Need help?</div>
                    <div
                      className="cursor-pointer underline"
                      onClick={() => {
                        router.push(
                          program
                            ? `/p/${program?.slug}/contact-support`
                            : `/contact-support`
                        );
                      }}
                    >
                      Contact Us
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              {showVerifyLoginError && authenticateOTPError && (
                <Alert className="mt-4" color="warning">
                  {authenticateOTPError.message}
                </Alert>
              )}
            </div>
          </form>
        </div>
      ) : (
        <div className={`${isModal && 'md:mt-10'}`}>
          <form
            className="flex flex-col md:mx-16"
            onSubmit={handleMobileSubmit}
          >
            <div>
              <div className="flex flex-col justify-center">
                {!isModal && (
                  <div className="mb-3 flex justify-center">
                    <div className="text-center text-xs text-black md:text-sm">
                      Weller Labs is a digital health platform used to teach
                      skills to patients
                    </div>
                  </div>
                )}
                <div className="mb-6 text-center text-sm font-semibold text-black md:text-lg ">
                  Please enter your mobile phone number to verify or create an
                  account:
                </div>
                <div className="flex justify-center">
                  <PhoneInput
                    className="form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-xl font-normal text-gray-700"
                    id="mobile"
                    type="mobile"
                    name="mobile"
                    style={{
                      maxWidth: '600px',
                      minWidth: isMobile ? '300px' : '350px',
                    }}
                    country="US"
                    international={true}
                    withCountryCallingCode={true}
                    value={mobileNumber}
                    onChange={handleMobileChange}
                  />
                </div>
              </div>
              {!isModal && (
                <div className="flex w-full justify-center">
                  <div className="mt-3 flex justify-center px-3 md:w-5/6">
                    <div className="text-center text-xs text-black">
                      By providing your mobile number, you agree that it may be
                      used to send you text messages to confirm and manage your
                      account. Message and data rates may apply.
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex w-full justify-center">
              <div className="absolute bottom-12 flex flex-col justify-center ">
                <div>
                  <div className="mb-8 flex w-full justify-center">
                    <Button
                      style={{
                        width: '170px',
                        borderRadius: '2rem',
                        backgroundColor: 'rgb(30 64 175)',
                      }}
                      className="bg-blue-800"
                      type="submit"
                      disabled={!mobileNumber || requestOTPLoading}
                    >
                      Continue
                      <IoArrowForward />
                    </Button>
                  </div>
                </div>
                <div className="flex w-full justify-center">
                  <div className="text-center align-baseline text-black">
                    <div className="mb-2 flex">
                      <div
                        className="cursor-pointer underline"
                        onClick={() => setShowMobileOTP(false)}
                      >
                        Use password instead
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                {showSendRegisterError && requestOTPError && (
                  <Alert className="mt-4" color="warning">
                    <OTPErrorHandler
                      message={requestOTPError.message}
                      onClick={() => setShowMobileOTP(false)}
                      slug={program ? program.slug : null}
                    />
                  </Alert>
                )}
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
